/*
    Form
*/
.form {

    // Elements
    &-fieldset {
        display: block;
        margin-bottom: rem(20);

        font-size: rem(18);

        &.is-required {
            .form-label::after {
                padding-left: rem(2);

                color: $color-warning;

                content: '*'
            }
        }
    }

    &-field {
        padding-right: rem(12);
        padding-left: rem(12);
    }

    &-label {
        display: inline-block;
        padding-left: rem(12);
        margin-bottom: rem(5);
    }

    &-validation {
        margin-left: rem(5);

        color: $color-warning;


    }

    &-result {
        // TODO: Refactor this animation technique to be more performant
        &.is-inactive {
            height: 0;
            margin-bottom: 0;

            opacity: 0;
            visibility: hidden;

            transition: 700ms;
        }

        &.is-active {
            height: rem(60);
            margin-bottom: rem(16);

            opacity: 1;
            visibility: visible;

            transition: 700ms;
        }
    }

    &-error {
        align-self: center;

        color: $color-warning;
    }

    &-actions {
        display: flex;
        justify-content: center;

        @media (--from-small-screen-w) {
            justify-content: flex-end;
        }

        .button {
            flex-basis: rem(200);
        }
    }
}
