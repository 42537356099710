/*
    Way Finding
*/
.way-finding {
    position: relative;

    display: flex;
    flex-direction: column;
    padding: rem(50) 0;

    @media (--from-normal-screen-w) {
        flex-direction: row;
    }


    // States
    &.is-loading {
        .way-finding-map,
        .way-finding-directions {
            z-index: -1;
        }

        .way-finding-map {
            opacity: 0.6;
        }
    }


    // Elements
    &-directions {
        @media (--from-normal-screen-w) {
            flex-basis: 40%;
        }
    }

    &-switch {
        display: flex;
        justify-content: center;
        height: rem(85);

        border: 1px solid $color-way-finding;
        border-right: none;


        // Elements
        &-button {
            display: flex;
            flex-basis: 50%;
            justify-content: center;
            padding: 0 rem(20);

            color: rgb(192, 192, 192);
            font-family: $font-primary;
            font-size: rem(18);
            white-space: normal;

            background-color: rgb(252, 252, 252);

            @media (--from-normal-screen-w) {
                align-items: center;
                flex-direction: column;
            }


            // States
            &.is-active {
                z-index: $layer-way-finding-active-button;

                color: $color-light;

                background-color: $color-primary;

                .icon {
                    color: $color-light;
                }
            }


            // Elements
            .icon {
                display: none;
                width: rem(28);
                height: rem(28);

                color: rgb(192, 192, 192);

                @media (--from-normal-screen-w) {
                    display: block;
                }
            }
        }
    }

    &-section {
        display: flex;
        flex-wrap: wrap;
    }

    &-map {
        position: relative;

        @media (--to-normal-screen-w) {
            height: rem(400);
        }

        @media (--from-normal-screen-w) {
            display: block;
            flex-basis: 60%;
        }


        // States
        &.is-hidden {
            display: none;
        }


        // Elements
        &-container {
            width: 100%;
            height: 100%;
        }

        &-element {
            width: 100%;
            height: 100%;
        }

        .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;

            transform: translate(-50%, -50%);
        }
    }
}
