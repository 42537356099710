/*
    New Featured Block
 */
 .new-featured-block {
    position: relative;

    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            flex-wrap: nowrap;
        }
    }

    .new-featured-tile {
        height: rem(500);
        position: relative;
        flex-basis: 33.33%;
        overflow: hidden;
        background-color: $color-secondary;

        &-inner {
            width: 100%;
            max-width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            color: $color-white;
            text-align: center;
            text-decoration: none;
            padding: rem(20);
            padding-top: rem(120);
            transition: transform 0.2s;

            @media (--from-large-screen-w) {
                padding-top: rem(180);
            }

            &:hover {
                transform: scale(1.02);
            }
        }

        &-heading-group {
            z-index : $layer-featured-block-title;
            max-width: 100%;

            &-title::after {
                display: block;
                content: '';
                height: rem(2);
                width: rem(220);
                background-color: $color-primary;
                text-align: center;
                margin: auto;
                margin-top: rem(10);
                margin-bottom: rem(20);
            }

            &-detail {
                padding: 0;
                max-width: 100%;

                @media (--from-large-screen-w) {
                    padding: 0 rem(40);
                }
            }
        }

        .image-overlay {
            &::before {
                background: linear-gradient(to bottom, color($color-secondary alpha(70%)) 0%, color($color-secondary alpha(100%)) 100%);
            }
        }

        &-directions {
            position: absolute;
            top: rem(80);
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-decoration: none;
            color: $color-primary;
            font-size: rem(20);

            @media (--from-large-screen-w) {
                top: rem(130);
                font-size: rem(16);
            }

            &:hover {
                border-bottom: rem(1) solid;
            }

            > svg {
                width: rem(15);
                height: rem(15);
                margin-right: rem(5);
            }
        }

        &-socials {
            position: absolute;
            bottom: rem(100);
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: flex;

            @media (--from-medium-screen-w) {
                bottom: rem(60);
            }

            @media (--from-large-screen-w) {
                bottom: rem(100);
            }

            &-item {
                width: rem(25);
                height: rem(25);
                margin-right: rem(30);
                transition: color 0.2s;
                color: $color-white;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: $color-primary;
                }
            }
        }
    }
}