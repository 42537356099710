/*
    Event
*/
.event {
    &-summary {
        @media (--to-normal-screen-w) {
            padding: rem(20) rem(15);

            background-color: $color-light-shade-1;
            border-radius: rem(6);
        }

        li::before,
        li::after {
            display: none;
        }

        ul {
            padding: 0;
            margin: 0;
        }

        li {
            padding-left: rem(35);
            margin-bottom: rem(15);

            background-position: left rem(-6) top rem(-2);
            background-repeat: no-repeat;
            background-size: rem(30) auto;

            @media (--to-normal-screen-w) {
                background-position: left top rem(-2);
                background-size: rem(25) auto;
            }
        }
    }

    &-summary-title {
        @media (--to-normal-screen-w) {
            padding-left: rem(25);

            text-align: center;
        }
    }
}
