/*
    Accordion
 */
.accordion {
    padding-top: rem($section-spacing-small);
    padding-bottom: rem($section-spacing-small);

    background-color: $color-light-shade-1;

    @media (--from-medium-screen-w) {
        padding-bottom: rem($section-spacing);
    }


    // Elements
    &-item {
        border-top: 1px solid $color-light-shade-2;

        @media (--from-large-screen-w) {
            margin-left: rem(10);
        }


        //States
        &.is-active {
            background-color: $color-light;


            // Elements
            .icon-button {
                transform: rotate(180deg);
            }

            .accordion-item-content {
                display: block;

            }
        }


        // Elements
        &:last-child {
            border-bottom: 1px solid $color-light-shade-2;
        }

        &-header {
            position: relative;

            width: 100%;
            min-height: rem(40);
            padding-top: rem(20);
            padding-right: rem(10);
            padding-bottom: rem(20);
            padding-left: rem(15);
            overflow: hidden;

            text-align: left;

            background-color: transparent;

            appearance: none;

            @media(--from-medium-screen-w) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }


            // Elements
            .icon-button {
                color: $color-primary;

                transition: 0.15s transform ease-in-out;

                @media(--to-medium-screen-w) {
                    position: absolute;
                    top: rem(10);
                    right: 0;
                }
            }
        }

        &-title {
            width: 100%;
            padding-right: rem(50);
            margin-bottom: 0;

            white-space: normal;
        }

        &-content {
            display: none;
            padding-right: rem(10);
            padding-bottom: rem(20);
            padding-left: rem(15);
        }
    }

    .heading-3 {
        @media(--to-small-screen-w) {
            text-align: center;
        }

        @media(--from-small-screen-w) {
            padding-top: rem(20);
            padding-bottom: rem(20);
        }
    }
}
