/*
    Caption Carousel
 */
$--padding-top: 70;

.caption-carousel {
    position: relative;

    color: $color-dark;

    @media (--to-small-screen-w) {
        height: rem(70);
        overflow: hidden;

        transition: height 0.15s ease-in-out;
    }

    @media (--from-small-screen-w) {
        position: absolute;
        bottom: 0;
        left: 50%;

        width: 100%;

        transform: translateX(-50%);
    }


    // Sizes – Class based on character length in description
    &.height-small {
        @media (--to-small-screen-w) {
            height: calc(rem(50) + rem($--padding-top));
        }
    }

    &.height-medium {
        @media (--to-small-screen-w) {
            height: calc(rem(80) + rem($--padding-top));
        }
    }

    &.height-large {
        @media (--to-small-screen-w) {
            height: calc(rem(120) + rem($--padding-top));
        }
    }

    &.height-x-large {
        @media (--to-small-screen-w) {
            height: calc(rem(160) + rem($--padding-top));
        }
    }


    // Elements
    &-item {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        padding-top: rem($--padding-top);
        padding-right: rem(25);
        padding-left: rem(25);
        overflow-y: auto;

        opacity: 0;

        transform: translateY(rem(40));
        transition:
            transform 300ms ease-in-out,
            opacity 300ms ease-in-out;

        @media (--to-small-screen-w) {
            overflow: hidden;
        }

        @media (--from-small-screen-w) {
            top: auto;

            padding-top: 0;
            padding-right: rem(300);
            padding-bottom: rem(35);
            padding-left: rem(32);
        }

        @media (--from-large-screen-w) {
            padding-left: 0;
        }


        // States
        &.is-active {
            z-index: $layer-caption-carousel-slide;

            opacity: 1;

            transform: translateY(0);
            transition:
                transform 250ms 300ms ease-in-out,
                opacity 250ms 300ms ease-in-out;
        }
    }

    &-change-slide {
        position: absolute;
        top: rem(13);
        z-index: $layer-caption-carousel-slide;

        width: rem(48);
        height: rem(40);
        overflow: hidden;

        color: $color-light;

        background-color: $color-primary;

        appearance: none;

        @media (--from-small-screen-w) {
            top: auto;
            right: rem(90);
            bottom: rem(38);

            width: rem(55);
            height: rem(48);
        }


        // Types
        &.previous {
            right: rem(69); // Leaving one pixel gap between the buttons.

            border-top-left-radius: rem(24);
            border-bottom-left-radius: rem(24);

            @media (--from-small-screen-w) {
                right: rem(96); // Leaving one pixel gap between the buttons.
            }

            @media (--from-large-screen-w) {
                right: rem(56); // Leaving one pixel gap between the buttons.
            }


            // Elements
            .icon {
                transform: rotate(90deg);
            }
        }

        &.next {
            right: rem(20);

            border-top-right-radius: rem(24);
            border-bottom-right-radius: rem(24);

            @media (--from-small-screen-w) {
                right: rem(40);
            }

            @media (--from-large-screen-w) {
                right: 0;
            }


            // Elements
            .icon {
                transform: rotate(-90deg);
            }
        }
    }

    &-line {
        position: absolute;
        right: 0;
        bottom: 100%;
        left: 0;

        height: rem(3);

        background-color: $color-light-shade-3;

        @media (--from-small-screen-w) {
            bottom: 0;
        }


        // Elements
        &-slider {
            position: absolute;
            bottom: 0;

            height: rem(6);

            background-color: $color-primary;

            transition: left 250ms ease-in-out;
        }
    }
}
