/*
    Hero Carousel
 */
.hero-carousel {
    position: relative;


    // States
    &:hover .carousel .change-slide {
        opacity: 1;

        transition: opacity 0.2s ease-in-out 0.2s;

        &.is-disabled {
            opacity: 0.35;
        }
    }

    // Types
    &.primary {
        margin-bottom: rem(-30);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(-120);
        }

        &.no-negative-margin {
            margin-bottom: 0;
        }


        // Elements
        .hero-carousel-intro {
            padding-bottom: rem(40);

            @media (--from-medium-screen-w) {
                padding-bottom: rem(200);
            }
        }
    }

    &.desktop {
        @media(--to-small-screen-w) {
            display: none;
        }
    }

    &.mobile {
        @media(--from-small-screen-w) {
            display: none;
        }
    }

    &.compact {

        // Elements
        &::before {
            height: auto;
            min-height: 0;
        }

        .hero-carousel-intro {
            position: relative;

            padding-top: rem(50);

            @media (--from-small-screen-w) {
                padding-top: rem(120);
                padding-bottom: rem(20);
            }
        }
    }

    &.light {
        .hero-carousel-intro {
            color: inherit;
        }
    }


    // Elements
    &::before {
        display: block;
        height: rem(540);
        max-height: calc(100vh - rem(20) - rem($header-height-small));
        min-height: rem(400);

        content: '';

        @media (--from-small-screen-w) {
            height: rem(600);
        }
    }

    &-intro {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;

        width: 100%;
        padding-bottom: rem(20);

        color: $color-light;
        text-align: center;

        transform: translateX(-50%);

        @media (--from-medium-screen-w) {
            padding-bottom: rem(40);
        }
    }

    &-heading {
        color: inherit;

        font-size: rem(48);

        @media (--from-medium-screen-w) {
            font-size: rem(64);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(72);
        }

        &::after {
            display: block;
            height: rem(2);
            max-width: rem($hr-width-small);
            margin-top: rem(12);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-primary;

            content: '';

            @media (--from-medium-screen-w) {
                max-width: rem($hr-width);
            }
        }
    }

    &-description {
        color: inherit;

        @media (--from-small-screen-w) {
            font-size: rem(18);
        }
    }

    .carousel {
        @media (--from-medium-screen-w) {
            max-height: calc(100vh - rem($header-height) - rem($header-height));
        }


        // Elements
        &-inner {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        .change-slide {
            @media (--to-medium-screen-w) {
                display: none;
            }

            @media (--from-medium-screen-w) {
                opacity: 0;

                transition: opacity 0.2s ease-in-out;
            }
        }
    }
}
