/*
    Splash
 */
.splash {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - rem($header-height-small));
    padding-bottom: rem(50);

    color: $color-shark;

    // TODO: Find the correct image for this
    // background-image: url($folder-image/north-harbour-albany.jpg);

    @media (--from-small-screen-w) {
        min-height: 65vh;
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: rem(40) rem(20);

        text-align: center;
    }

    p a:not(.button) {
        color: $color-primary;

        border-bottom-color: currentColor;

        &:hover {
            color: $color-primary-tint-1;
        }
    }
}
