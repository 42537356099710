/*
    Content Blocks
 */
.content-blocks {

    // Relationships
    .text-content-block + .file-content-block {
        @media (--from-medium-screen-w) {
            margin-top: calc(rem(-$section-spacing-large) - rem(-50));
        }
    }

    .file-content-block + .file-content-block {
        margin-top: calc(rem(-$section-spacing-large) - rem(-50));
    }

    .call-to-action + .text-content-block.content-section {
        @media (--to-small-screen-w) {
            margin-top: rem(15);
        }
    }

    .accordion + .call-to-action {
        @media (--to-small-screen-w) {
            padding-top: rem(20);
        }
    }

    .call-to-action + .tile-block {
        @media (--to-small-screen-w) {
            padding-top: rem(20);
        }
    }

    .tile-block + .tile-block {
        padding-top: rem(15);
    }

    .accordion + .video-content-block,
    .accordion + .accordion {
        padding-top: 0;
    }

    .gallery,
    .call-to-action {

        // Order
        &:first-child {
            margin-top: rem($section-spacing-small);

            @media (--from-small-screen-w) {
                margin-top: rem($section-spacing);
            }

            @media (--from-medium-screen-w) {
                margin-top: rem($section-spacing-large);
            }
        }

        &:last-child {
            @media (--from-small-screen-w) {
                margin-bottom: rem($section-spacing);
            }

            @media (--from-medium-screen-w) {
                margin-bottom: rem($section-spacing-large);
            }
        }


        // Relationship
        & + .call-to-action,
        & + .gallery {
            @media (--from-small-screen-w) {
                margin-top: rem($section-spacing);
            }

            @media (--from-medium-screen-w) {
                margin-top: rem($section-spacing-large);
            }
        }
    }
}
