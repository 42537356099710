/*
    Fonts
*/
@font-face {
    font-family: UniSansBook;
    font-style: normal;
    font-weight: 400;
    src: url('../../static/fonts/uni-sans/book.woff') format('woff'),
        url('../../static/fonts/uni-sans/book.woff2') format('woff2');
}

@font-face {
    font-family: UniSansSemibold;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/uni-sans/semibold.woff') format('woff'),
        url('../../static/fonts/uni-sans/semibold.woff2') format('woff2');
}
