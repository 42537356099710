/*
    Header
 */
.header {
    word-break: normal;

    background-color: $color-light;
    box-shadow: $header-shadow;


    // Elements
    &-logo {
        display: flex;
        align-items: center;

        svg {
            @media (--from-normal-screen-w) {
                width: rem(175);
                height: rem(55);
            }
        }
    }

    &-inner {
        display: flex;
        height: rem($header-height-small);

        @media (--from-medium-screen-w) {
            height: rem($header-height);
        }
    }

    &-actions {
        @media (--from-medium-screen-w) {
            display: none;
        }
    }

    &-side-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: rem(90);

        @media (--to-medium-screen-w) {
            flex-grow: 1;
        }

        @media (--from-medium-screen-w) {
            order: 2;
            justify-content: space-between;
            width: rem(90);
        }
    }

    &-notification-toggle {
        position: relative;

        width: rem(40);
        height: rem(40);

        color: $color-primary;

        border: none;

        &, &:hover {
            background: transparent;
        }


        // States
        &.is-active {
            &:before {
                opacity: 0;
            }
        }


        // Pseudo
        &:before {
            position: absolute;
            top: rem(11);
            right: rem(9);

            display: block;
            width: rem(8);
            height: rem(8);

            background-color: $color-primary;
            border-radius: 50%;
            content: '';
            opacity: 1;

            transition: opacity 0.15s ease-in-out;
        }


        // Elements
        .icon {
            width: rem(26);
            height: rem(26);

            @media (--from-medium-screen-w) {
                width: rem(28);
                height: rem(28);
            }
        }
    }
}
