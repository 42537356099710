/*
    Performance intro
 */
.performance-intro {

    // Elements
    &-where-and-when {
        margin-bottom: rem(5);


        // Elements
        svg {
            width: rem(30);
            height: rem(30);

            vertical-align: middle;
        }
    }

    &-calendar-link {
        display: block;

        color: inherit;
        text-decoration: none;


        // Elements
        svg {
            width: rem(30);
            height: rem(30);

            vertical-align: middle;
        }

        span {
            border-bottom: rem(1) solid $color-primary;
        }
    }
}
