/*
    Hero Video
*/
.hero-video {
    position: relative;
    width: 100%;
    height: rem(540);
    overflow: hidden;

    @media (--from-small-screen-w) {
        height: rem(600);
    }

    .hero-video-heading {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 50%;
        bottom: rem(20);
        color: #ffffff;
        transform: translateX(-50%);
        text-align: center;

        @media (--from-medium-screen-w) {
           bottom: rem(120);
        }

        &::after {
            display: block;
            height: rem(2);
            max-width: rem(248);
            margin-top: rem(12);
            margin-right: auto;
            margin-left: auto;
            background-color: $color-primary;
            content: "";

            @media (--from-medium-screen-w) {
                max-width: rem(400);
             }
        }
    }

    .youtube-player {
        position: absolute;
        width: rem(960);
        height: 100%;
        top: 50%;
        left: 50%;
        padding-bottom: calc(100% * 9 / 16); // Youtube aspect ratio 16 : 9
        transform: translate(-50%, -50%);

        @media (--from-small-screen-w) {
            width: rem(1067);
        }

        @media (min-width: 1067px) {
            width: 100%;
            height: 0;
            padding-bottom: calc(100% * 9 / 16); // Youtube aspect ratio 16 : 9
        }
    }

    iframe#player {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 0;
    }

    .thumbnail-image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        

        .icon {
            color: #ffffff;
            width: rem(60);
            height: rem(60);
        }
    }
}