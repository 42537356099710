/*
    Labeled Content
 */
.labeled-content {

    // Features
    body:not(.feature-stadiums-new-footer) & {
        display: flex;
    }


    // Elements
    &-label {
        font-family: $font-primary;
        font-weight: $weight-semi-bold;


        // Features
        body:not(.feature-stadiums-new-footer) & {
            text-align: right;

            @media (--from-normal-screen-w) {
                margin-left: rem(20);
            }
        }


        // Elements
        p {
            margin-bottom: 0;
        }
    }

    &-main {
        font-size: rem(18);


        // Features
        body:not(.feature-stadiums-new-footer) & {
            margin-left: rem(20);

            @media (--from-small-screen-w) {
                margin-left: rem(45);
            }

            @media (--from-normal-screen-w) {
                margin-left: rem(30);
            }
        }

        // Elements
        p {
            line-height: 1.4;
        }
    }
}
