/*
    Pre Footer
 */
.pre-footer {
    position: relative;


    // Elements
    &-inner {

        @media (--from-normal-screen-w) {
            display: flex;
        }
    }

    &-title {
        padding-top: rem(10);
        margin-bottom: rem(20);

        @media (--from-medium-screen-w) {
            padding-top: rem(35);
        }

        @media (--from-normal-screen-w) {
            margin-bottom: rem(32);
        }


        // Features
        body:not(.feature-stadiums-new-footer) & {
            position: relative;

            padding-top: rem(15);
            margin-bottom: rem(50);

            text-align: center;

            @media (--from-small-screen-w) {
                padding-top: rem(35);
                margin-bottom: rem(70);
            }

            @media (--from-normal-screen-w) {
                padding-top: rem(45);
                margin-bottom: rem(90);
                margin-left: rem(20);

                text-align: left;
            }
        }
    }

    &-main {
        position: relative;


        // Features
        body:not(.feature-stadiums-new-footer) & {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;

        background-size: cover;

        @media (--from-normal-screen-w) {
            width: calc(50% - rem(100));
            height: 100%;
        }
    }
}
