/*
    Enquiry
 */
.enquiry {
    @media (--from-normal-screen-w) {
        width: calc(50% + rem(100));
        padding-top: rem(32);
        padding-left: rem(85);
    }

    // Features
    body:not(.feature-stadiums-new-footer) & {
        padding: rem(17) rem(12) rem(30);

        @media (--from-small-screen-w) {
            padding: rem(32) rem(22) rem(25);
        }

        @media (--from-normal-screen-w) {
            padding-left: rem(85);
        }
    }
}
