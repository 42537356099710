/*
    Tabs
*/
.tabs {
    &-tab-list {
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-bottom: 0;

        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }

    &-tab {
        display: block;
        padding-top: rem(20);
        padding-right: rem(10);
        padding-bottom: rem(20);
        padding-left: rem(10);
        margin-bottom: 0;

        font-family: $font-primary;
        font-weight: $weight-semi-bold;

        cursor: pointer;

        user-select: none;

        @media (--from-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }


        // Pseudo
        &::before,
        &::after {
            content: none;
        }


        // States
        &.is-active {
            color: $color-primary;

            box-shadow: 0 rem(2) 0 0 $color-primary;
        }
    }
}