/*
    Action Button
 */
$action-button-height: 40;
$action-button-min-width: 40;

.action-button {
    transition: color 0.3s;


    // States
    &:hover {
        @media (--from-medium-screen-w) {
            color: $color-primary;
        }
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem($action-button-height);
        min-width: rem($action-button-min-width);
    }

    &-label {
        margin-bottom: 0;

        font-family: $font-primary;
        font-size: rem(16);
        font-weight: $weight-semi-bold;
        line-height: 1;
        letter-spacing: 0.01em;

        @media (--from-small-screen-w) {
            font-size: rem(18);
        }

        &:last-child {
            padding-left: rem(10);

            @media (--from-medium-screen-w) {
                padding-left: rem(15);
            }
        }

        &:first-child {
            padding-right: rem(10);

            @media (--from-medium-screen-w) {
                padding-right: rem(15);
            }
        }
    }

    .icon {
        width: rem(30);
        height: rem(30);

        color: $color-primary;
    }
}
