/*
    Featured Tile
 */
.featured-tile {
    position: relative;

    margin-bottom: rem(45);

    @media (--from-medium-screen-w) {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
        padding-right: calc(rem($grid-spacing-large) / 2);
        padding-left: calc(rem($grid-spacing-large) / 2);
    }


    // Elements
    &-inner {
        position: relative;

        display: block;
        height: rem(400);
        overflow: hidden;

        color: $color-dark;
        text-decoration: none;

        box-shadow: 0 rem(5) rem(30) color($color-dark alpha(20%));


        // States
        &:hover,
        &:focus {
            cursor: pointer;


            // Elements
            .featured-tile-background {
                transform: scale(1.02);


                // Elements
                &::before {
                    opacity: 0;
                }
            }
        }
    }

    &-background {
        position: absolute;
        top: rem(102);
        right: 0;
        bottom: 0;
        left: 0;

        transition: transform 200ms ease-in-out;


        // Elements
        &::before {
            opacity: 0.75;

            transition: opacity 200ms ease-in-out;
        }
    }

    &-directions {
        position: absolute;
        bottom: 0;
        left: 50%;

        min-width: rem(200);

        white-space: nowrap;

        transform: translate(-50%, 50%);
    }

    &-heading-group {
        position: relative;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: rem(142);
        padding-right: rem(42);
        padding-bottom: rem(40);
        padding-left: rem(42);
        margin-top: rem(-2);
        margin-right: rem(-2);
        margin-left: rem(-2);

        font-size: rem(24);
        line-height: rem(28);
        text-align: center;

        background-image: url($data-uri-card-swoosh-green);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% 100%;


        // Elements
        &-title {
            width: 100%;
        }

        .icon {
            position: absolute;
            top: rem(30);
            right: rem(10);

            color: $color-primary;

            transform: rotate(-90deg) ;
        }
    }
}
