/*
    Featured Block
 */
.featured-block {
    position: relative;
    z-index: $layer-featured-block;

    margin-bottom: calc(rem(80) - rem($grid-spacing));

    @media (--from-large-screen-w) {
        margin-bottom: calc(rem(80) - rem($grid-spacing-large));
    }


    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            flex-wrap: nowrap;
            margin-right: calc(rem(-$grid-spacing-large) / 2);
            margin-bottom: rem($grid-spacing-large);
            margin-left: calc(rem(-$grid-spacing-large) / 2);
        }
    }
}
