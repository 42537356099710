.search-grids-tile {

    margin-bottom: rem($grid-spacing);

    text-align: left;
    @media (--from-medium-screen-w) {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3); // IE flex-wrap
        padding-right: calc(rem($grid-spacing) / 2);
        padding-left: calc(rem($grid-spacing) / 2);
    }

    @media (--from-normal-screen-w) {
        padding-right: calc(rem($grid-spacing-large) / 2);
        padding-left: calc(rem($grid-spacing-large) / 2);
        margin-bottom: rem($grid-spacing-large);
    }

    &-inner {
        @media (--to-small-screen-w) {
            border-bottom: 2px solid $color-mercury;
        }

        @media (--from-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }
    }

    // States
    &:hover {
        @media (--from-normal-screen-w) {
            .tile-thumbnail {
                opacity: 0.5;
            }

            .text-link {
                opacity: 1;
            }
        }
    }
}