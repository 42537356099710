/*
    New Footer
*/
.footer {

    // Sections
    &-primary {
        position: relative;

        padding-top: rem(40);
        margin-bottom: rem(60);

        color: $color-light;

        background-color: $color-secondary;

        &::after {
            content: '';
            display: block;
            position: absolute;
            background: $color-secondary;
            top: calc(100% - rem(80) / 2);
            left: 0;
            z-index: -1;

            width: 100%;
            height: rem(80);

            border-radius: 100%;
        }
    }

    &-secondary {
        margin-bottom: rem(20);

        background-color: $color-light;
    }

    &-inner {
        & > * {
            width: 100%;
            border-top: 1px solid $color-light;
            padding: rem(30) 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @media (--from-medium-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                text-align: left;
            }

            & > *:not(:last-child) {
                margin-bottom: rem(30);

                @media (--from-medium-screen-w) {
                    margin-bottom: 0;
                }
            }
        }

        .heading {
            text-transform: uppercase;
            font-size: rem(14);
            margin-bottom: rem(10);
        }

        .contact-info {
            display: flex;
            justify-content: center;

            @media (--from-medium-screen-w) {
                justify-content: flex-start;
            }

            p {
                font-size: rem(16);
                margin-bottom: rem(5);
            }

            &-label {
                margin-left: 0;
                color: $color-primary;
            }

            &-main {
                margin-left: rem(10);
            }
        }

        p a:not(.button) {
            color: $color-light;
            border-bottom: none;

            &:hover {
                color: $color-primary;
            }
        }
    }

    &-subscription {
        .general-enquiries,
        .subscription {
            width: 100%;
        }

        .subscription {
            max-width: rem(300);
            
            @media (--from-medium-screen-w) {
                width: rem(300);
                align-self: flex-start;
                flex-shrink: 0;
                flex-grow: 0;
            }

            .button {
                text-transform: uppercase;
                width: rem(100);
            }
        }
    }

    .form-field {
        background-color: $color-light;

        &:hover {
            border: none;
        }
    }

    &-info {
        padding-bottom: 0;

        .link {
            color: $color-light;
            display: block;
    
            &:hover {
                color: $color-primary;
                text-decoration: underline;
            }
        }

        .social-links {
            display: flex;
            justify-content: center;
            align-items: center;

            & > * {
                display: block;
                margin: 0 rem(10);
            }

            .heading {
                margin-top: rem(10);
            }
        }

        .logo {
            margin-bottom: 0;
        }

        svg {
            fill: $color-light;
        }
    }

    &-outro {
        // Elements
        p {
            width: fit-content;
            margin: auto;
            margin-bottom: rem(10);

            font-size: rem(16);

            @media (--to-medium-screen-w) {
                text-align: center;
            }
        }
    }

    &-secondary a {
        border-bottom: none;
    }

    &-text-spacer {
        padding-right: rem(10);
        padding-left: rem(10);

        @media (--to-small-screen-w) {
            display: none;
        }
    }

    &-text-break {
        @media (--from-small-screen-w) {
            display: none;
        }
    }
    
}