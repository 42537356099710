/*
    Seat Finder Section
*/
.seat-finder-section {
    display: flex;
    flex-wrap: wrap;

    border-bottom: 1px solid $color-way-finding;


    // States
    &.is-hidden {
        display: none;
    }


    // Elements
    &-description {
        flex-basis: 100%;
        padding: rem(15) rem(40);

        font-size: rem(18);
    }

    &-ticket {
        display: flex;
        flex-direction: column;

        padding: rem(20) rem(40) rem(60) rem(40);


        // Elements
        &-description,
        &-error {
            margin-bottom: rem(10);

            font-size: rem(18);
        }

        &-description {
            padding-top: rem(20);
        }

        &-image {
            width: 100%;

            @media(--from-medium-screen-w) and (--to-normal-screen-w) {
                max-width: rem(700);
            }


            @media (--from-normal-screen-w) {
                max-height: rem(230);
            }
        }

        &-error {
            color: rgb(209, 0, 26);
        }

        &-directions {
            padding-top: rem(25);

            font-size: rem(18);
        }
    }
}
