/*
    Heading
 */
// Headings
h1, .heading-1,
h2, .heading-2,
h3, .heading-3,
h4, .heading-4,
h5, .heading-5,
h6, .heading-6 {
    margin-top: 0;
    margin-bottom: rem(20);

    font-weight: $weight-regular;


    // Types
    &.title {
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }

    &.light {
        font-family: $font-tertiary;
        font-weight: $weight-regular;
    }
}

h1, .heading-1,
h2, .heading-2,
h4, .heading-4,
h5, .heading-5 {
    font-family: $font-primary;
    font-weight: $weight-semi-bold;
}

// Heading 1
h1, .heading-1 {
    font-size: rem(48);
    line-height: 1.05;

    @media (--from-small-screen-w) {
        font-size: rem(64);
    }
}

// Heading 2
h2, .heading-2 {
    font-size: rem(36);
    line-height: 1.14;
}

// Heading 3
h3, .heading-3 {
    font-size: rem(36);
    line-height: 1.14;
}

// Heading 4
h4, .heading-4 {
    font-size: rem(20);
    line-height: 1.26;

    @media (--from-small-screen-w) {
        font-size: rem(22);
    }
}

// Heading 5
h5, .heading-5 {
    font-size: rem(18);
}

// Heading 6
h6, .heading-6 {
    font-size: rem(16);
}
