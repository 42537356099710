/*
    Checkbox
 */
.checkbox {
    position: absolute;
    left: -100vw; // Hide the native input element


    &-form-field {
        position: relative;

        display: flex;
        margin-bottom: rem(2);
    }

    &-icon {
        flex-grow: 0;
        flex-shrink: 0;
        width: rem(32);
        height: rem(32);
        margin-right: rem(4);

        color: $color-light-shade-3;
    }

    &:focus + &-icon {
        color: $color-primary;
    }

    &-label {
        display: block;
        flex-grow: 1;
        padding-top: rem(6);

        font-size: rem(18);
        line-height: 1.2;
    }
}
