/*
    Type
    - Normal text
    - Quote
    - Address
    - Link
 */
html {
    color: $color-secondary;

    font-family: $font-tertiary;
    font-size: 16px;
    line-height: 1.5;
    word-break: break-word;
    word-wrap: break-word;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}


// Normal text
p {
    margin-top: 0;
    margin-bottom: rem(20);

    @media (--from-small-screen-w) {
        font-size: rem(18);
    }
}

b,
strong {
    font-family: $font-primary;
    font-weight: $weight-semi-bold;
}

em {
    font-style: italic;
}

small {
}


// Quote
blockquote,
q {
    quotes: '“' '”' '‘' '’';

    p::before {
        content: open-quote;
    }

    p::after {
        content: close-quote;
    }
}

cite {
}


// Address
address {
    font-style: normal;
}


// Link
p a,
li a {
    color: $color-secondary;
    text-decoration: none;

    border-bottom: rem(1) solid $color-light-shade-3;

    transition: color 0.15s ease-in-out;

    &:hover {
        color: $color-primary;

        border-bottom-color: $color-primary;
    }
}
