/*
    New Tile
 */
.new-tile {

    // Elements
    &-inner {
        position: relative;

        display: flex;
        flex-direction: row;
        overflow: hidden;
        height: rem(200);

        color: $color-dark;
        text-decoration: none;

        background-color: $color-light;
        box-shadow: 0 8px 20px 0 $color-light-shade-3;

        border-radius: 10px;

        @media (--from-medium-screen-w) {
            flex-direction: column;
            height: auto;
        }

        // States
        &:hover,
        &:focus {
            cursor: pointer;

            // Elements
            @media (--from-medium-screen-w) {
                .new-tile-image {
                    transform: scale(1.02);

                    &::before {
                        opacity: 0.35;
                    }
                }
            }
        }
    }

    &-image {
        height: 100%;
        width: 70%;
        transition: transform 200ms ease-in-out;
        background-color: $color-secondary-tint-1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--from-medium-screen-w) {
            height: rem(250);
            width: 100%;
        }
    }

    &-description {
        width: 100%;
        padding: rem(20) rem(20) 0;
        height: 100%;

        @media (--from-medium-screen-w) {
            height: rem(160);
        }

        h5 {
            margin-bottom: rem(10);
            font-size: rem(16);

            @media (--from-normal-screen-w) {
                font-size: rem(18);
            }
        }

        p {
            font-size: rem(14);
            color: $color-dark-tint-2;
        }
    }
}

.new-tile-event {
    .event-date {
        font-weight: $weight-bold;
        margin-bottom: rem(10);
        color: $color-black;

        @media (--from-medium-screen-w) {
            margin-bottom: rem(5);
        }
    }

    .new-tile-description {
        border-left: rem(8) solid $color-primary;

        @media (--from-medium-screen-w) {
            border: none;
            border-top: rem(8) solid $color-primary;
        }
    }

    .icon {
        width: rem(15);
        height: rem(15);
        color: $color-primary;
        margin-right: rem(5);
    }

    .location {
        position: absolute;
        bottom: rem(15);
        margin-bottom: 0;
    }
}