/*
    Navigation Bar
 */
.navigation-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (--from-medium-screen-w) {
        position: relative;

        flex-grow: 1;
    }

    @media (--from-normal-screen-w) {
        justify-content: center;
    }


    // States
    &.is-active &-inner {
        transform: translateX(0);
        transition: transform $overlay-transition-open;
    }


    // Elements
    &-inner {
        @media (--to-medium-screen-w) {
            position: fixed;
            top: rem($header-height-small);
            left: 0;

            width: 100%;
            height: calc(100vh - rem($header-height-small));
            padding-bottom: rem(50);
            overflow-y: scroll;

            color: $color-light;

            background-color: $color-secondary;

            transform: translateX(100%);
            transition: transform $overlay-transition-close;
        }

        @media (--from-medium-screen-w) {
            display: flex;
            align-items: center;
        }
    }
}
