/*
    List
    - Ordered
    - Unordered
    - List item
 */
$ul-bullet-size: 14;
$ol-bullet-size: 20;

ul,
ol {
    padding-left: rem(22);
    margin-top: 0;
    margin-bottom: rem(20);

    @media (--from-small-screen-w) {
        padding-left: rem(42);
    }
}

li {
    position: relative;

    margin-bottom: rem(8);

    @media (--from-small-screen-w) {
        font-size: rem(18);
    }

    &::before {
        position: absolute;
        left: rem(-10);

        display: block;

        border-radius: 50%;

        content: '';

        transform: translateX(-100%);
    }
}

ol {
    list-style: none;
    counter-reset: ordered-list;

    li::before {
        top: rem(4);

        width: rem($ol-bullet-size);
        height: rem($ol-bullet-size);

        color: $color-light;
        font-size: rem(12);
        line-height: rem($ol-bullet-size);
        text-align: center;

        background-color: $color-primary;

        content: counter(ordered-list);
        counter-increment: ordered-list;
    }
}

ul {
    list-style: none;

    li::before {
        top: rem(6);

        width: rem($ul-bullet-size);
        height: rem($ul-bullet-size);

        background-color: $color-primary;
        // TODO: Figure out how to include/reference assets in SASS
        // background-image: url('/static/assets/images/bullet.svg');
    }

    // This is the alternative hack to solve the problem above using CSS
    li::after {
        position: absolute;
        top: rem(11);
        left: rem(-22);

        display: block;
        width: rem(20);
        height: rem(20);

        pointer-events: none;
        border-radius: 50%;
        box-shadow: rem(2) rem(2) 0 0 $color-light;

        content: '';

        transform: rotate(145deg);
    }
}
