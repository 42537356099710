/*
    Directions Section
*/
.directions-section {
    display: flex;
    flex-wrap: wrap;


    // States
    &.is-hidden {
        display: none;
    }

    // Elements
    &-description {
        flex-basis: 100%;
        padding: rem(15) rem(20);

        font-size: rem(18);

        @media (--from-normal-screen-w) {
            text-align: center;
        }
    }

    &-options {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: center;

        border-bottom: 1px solid $color-way-finding;
    }

    &-button {
        display: flex;
        align-items: center;
        flex-basis: 50%;
        flex-direction: column;
        justify-content: center;
        height: rem(150);
        max-width: 50%;
        padding: rem(20);

        color: $color-secondary;
        font-family: $font-tertiary;
        font-size: rem(16);
        text-decoration: none;
        text-transform: uppercase;
        white-space: normal;

        background-color: rgb(250, 250, 250);

        border-top: 1px solid $color-way-finding;
        outline: none;

        transition: background-color 0.25s ease-in-out;

        @media (--from-normal-screen-w) {
            height: rem(185);
        }


        // States
        &.is-active {
            z-index: $layer-way-finding-active-button;

            color: $color-light;

            background-color: $color-primary;

            .icon {
                color: $color-light;
            }
        }


        // Elements
        &:nth-child(even) {
            border-left: 1px solid $color-way-finding;
        }

        .icon {
            width: rem(65);
            height: rem(65);

            color: $color-primary;
        }

        .travel-mode {
            margin-bottom: rem(10);
        }

        .distance, .duration {
            padding: rem(5);

            font-size: rem(14);
            text-transform: lowercase;
        }

        .duration {
            font-weight: $weight-bold;
        }

        .required-message {
            padding-top: rem(10);

            font-size: rem(14);
            text-transform: none;
        }
    }

    &-form {
        display: none;

        width: 100%;

        @media (--from-normal-screen-w) {
            display: block;
        }


        // Elements
        &-field {
            display: flex;
            justify-content: space-between;
            height: rem(55);


            // Types
            &:first-of-type {
                border: 1px solid $color-way-finding;
                border-right: none;
                border-left: none;
            }
        }

        &-label,
        &-input {
            font-family: $font-primary;
            font-size: rem(18);
        }

        &-label {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 85px;

            border-right: 1px solid $color-way-finding;


            // Types
            &:first-of-type&:before  {
                border-bottom: 1px solid $color-way-finding;
            }


            // Elements
            &:before {
                position: absolute;
                left: 0;

                width: rem(8);
                height: rem(55);

                background-color: $color-primary;

                content: '';
            }
        }

        &-input {
            z-index: $layer-way-finding-form-element;

            width: 100%;
            padding: rem(20);

            text-overflow: ellipsis;

            border: none;


            // States
            &:disabled {
                color: $color-secondary;

                background-color: $color-light;
            }


            // Elements
            &::placeholder {
                color: rgb(216, 216, 216);
                font-family: $font-tertiary;
                font-size: rem(18);
            }
        }

        &-search-box {
            display: flex;
            width: 100%;
        }

        &-location-button {
            z-index: $layer-way-finding-form-element;

            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(230);
            padding: rem(10);

            font-family: $font-tertiary;
            font-size: rem(14);

            .icon {
                width: rem(32);
                height: rem(32);
            }
        }
    }
}
