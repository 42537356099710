/*
    Image Carousel
 */
.image-carousel {
    overflow: hidden;


    // Elements
    .image-overlay::before {
        height: 50%;

        background: linear-gradient(to bottom, color($color-secondary alpha(0%)) 0%, color($color-secondary alpha(30%)) 40%, color($color-secondary alpha(100%)) 100%);

        content: '';
    }

    &-item {
        position: relative;

        display: inline-block;

        background-color: $color-dark-tint-1;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &-item,
    .slick-track {
        width: 100%;
        height: rem(225);

        @media (--from-small-screen-w) {
            height: rem(600);
        }
    }

    // Title nested inside of slides for screen readers
    .screen-reader-only-text {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}
