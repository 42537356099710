/*
    Invite Form
 */
.invite-form {
    max-width: rem(540);

    // Elements
    &-add-invitee {
        display: inline-block;
        padding: 0;
        margin: 0;

        color: $color-primary;
        font-family: $font-primary;
        font-size: rem(18);
        font-weight: $weight-semi-bold;

        background-color: transparent;
        border: 0;
    }

    &-invitee {
        padding-top: rem(25);

        @media (--from-medium-screen-w) {
            padding-top: rem(35);
        }
    }

    &-actions {
        padding-top: rem(15);


        // Elements
        .button {
            width: rem(160);
        }
    }

    .g-recaptcha {
        margin-top: rem(30);

        @media (--from-medium-screen-w) {
            margin-top: rem(45);
        }
    }
}
