/*
    Tile
 */
.tile {

    // Elements
    &-inner {
        position: relative;

        display: flex;
        height: rem(138);
        overflow: hidden;

        color: $color-secondary;
        text-decoration: none;

        background-color: $color-light;
        box-shadow: 0 8px 20px 0 $color-light-shade-3;


        // States
        &:hover,
        &:focus {
            cursor: pointer;


            // Elements
            @media (--from-medium-screen-w) {
                .tile-image {
                    transform: scale(1.02);

                    &::before {
                        opacity: 0.35;
                    }
                }
            }
        }

        @media (--from-medium-screen-w) {
            height: rem(300);
        }

        @media (--from-normal-screen-w) {
            height: rem(400);
        }
    }

    &-description {
        position: absolute;
        top: 50%;
        left: rem(96);

        padding-right: rem(25);
        padding-left: rem(15);

        transform: translateY(-50%);

        @media (--from-medium-screen-w) {
            top: auto;
            bottom: 0;
            left: 0;

            padding-right: rem(30);
            padding-left: rem(20);
            margin-bottom: rem(10);

            color: $color-light;

            transform: translateY(0);
        }

        @media (--from-normal-screen-w) {
            padding-right: rem(40);
            margin-bottom: rem(30);
        }
    }

    &-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: rem(96);

        transition: transform 200ms ease-in-out;

        @media (--to-medium-screen-w) {
            &::before {
                opacity: 0;
            }
        }

        @media (--from-medium-screen-w) {
            width: 100%;
        }

        &::before {
            transition: opacity 200ms ease-in-out;
        }
    }

    .icon {
        position: absolute;

        top: 50%;
        right: 0;

        color: $color-primary;

        transform: translateY(-50%) rotate(270deg);

        @media (--from-medium-screen-w) {
            top: auto;
            bottom: rem(10);
        }

        @media (--from-normal-screen-w) {
            right: rem(10);
            bottom: rem(30);
        }
    }

    p {
        margin-bottom: rem(0);
    }

    h4 {
        margin-bottom: rem(5);
    }
}
