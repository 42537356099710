/*
    Tile Block
*/
.tile-block {
    padding-top: rem(40);
    padding-bottom: calc(rem(30) - rem($grid-spacing));

    @media (--from-medium-screen-w) {
        padding-top: rem(80);
        padding-bottom: calc(rem(80) - rem($grid-spacing-large));
    }


    // Elements
    &-title {
        padding-bottom: rem(20);

        text-align: center;

        @media (--from-medium-screen-w) {
            text-align: left;
        }
    }

    &-inner {
        @media (--from-medium-screen-w) {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + rem($grid-spacing));
            margin-right: calc(rem(-$grid-spacing) / 2);
            margin-left: calc(rem(-$grid-spacing) / 2);
        }

        @media (--from-normal-screen-w) {
            width: calc(100% + rem($grid-spacing-large));
            margin-right: calc(rem(-$grid-spacing-large) / 2);
            margin-left: calc(rem(-$grid-spacing-large) / 2);
        }
    }

    .tile,
    .new-tile {
        margin-bottom: rem($grid-spacing);

        @media (--from-medium-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3); // IE flex-wrap
            padding-right: calc(rem($grid-spacing) / 2);
            padding-left: calc(rem($grid-spacing) / 2);
        }

        @media (--from-normal-screen-w) {
            padding-right: calc(rem($grid-spacing-large) / 2);
            padding-left: calc(rem($grid-spacing-large) / 2);
            margin-bottom: rem($grid-spacing-large);
        }
    }
}
