/*
    Page Header
 */
.page-header {
    position: relative;

    height: rem(540);

    @media (--to-small-screen-w) {
        max-height: calc(100vh - rem(20) - rem($header-height-small));
        min-height: rem(400);
    }

    @media (--from-small-screen-w) {
        height: rem(600);
    }

    color: $color-light;

    background-color: $color-secondary-tint-1;


    // Types
    &.primary {
        margin-bottom: rem(-30);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(-120);
        }


        // Elements
        .page-header-inner {
            padding-bottom: rem(40);

            @media (--from-medium-screen-w) {
                padding-bottom: rem(200);
            }
        }
    }


    // Elements
    &-inner {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        padding-bottom: rem(20);

        text-align: center;

        @media (--from-medium-screen-w) {
            padding-bottom: rem(40);
        }
    }

    &-title {
        font-size: rem(48);

        @media (--from-medium-screen-w) {
            font-size: rem(64);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(72);
        }

        &::after {
            display: block;
            height: rem(2);
            max-width: rem($hr-width-small);
            margin-top: rem(12);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-primary;

            content: '';

            @media (--from-medium-screen-w) {
                max-width: rem($hr-width);
            }
        }
    }
}
