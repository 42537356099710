/*
    Select
*/
.select {
    background-image: url($folder-image/select-arrow.svg);
    background-position: calc(100% - rem(5)) 50%;
    background-repeat: no-repeat;
    background-size: rem(40) auto;

    &::-ms-expand {
        display: none;
    }

    // Custom style for react select
    &__control {
        &&--is-focused {
            border-color: $color-primary;
            box-shadow: none;

            &:hover {
                border-color: $color-secondary;
            }
        }
    }

    &.is-invalid &__control {
        border-color: $color-warning;
    }

    &__option {
        &&--is-selected {
            background-color: $color-primary;
        }
    
       &&--is-focused:not(&--is-selected) {
            background-color: $color-light-shade-1;
        }
    }
}
