/*
    Content Section
 */
.content-section {
    margin-top: rem($section-spacing-tiny);
    margin-bottom: rem($section-spacing-tiny);

    @media (--from-small-screen-w) {
        margin-top: rem($section-spacing);
        margin-bottom: rem($section-spacing);
    }

    @media (--from-medium-screen-w) {
        margin-top: rem($section-spacing-large);
        margin-bottom: rem($section-spacing-large);
    }


    // Elements
    &-inner {
        @media(--from-normal-screen-w) {
            display: flex;
        }
    }

    &-main {
        @media (--to-normal-screen-w) {
            margin-bottom: rem(35);
        }

        @media (--from-normal-screen-w) {
            flex-shrink: 0;
            width: rem(700);
            padding-right: rem(20);
        }
    }

    &-aside {
        @media(--from-normal-screen-w) {
            flex-grow: 1;
            // Stop the border extending the length of the adjacent content
            height: min-content;
            padding-top: rem(10);
            padding-left: rem(25);

            border-left: 1px solid $color-light-shade-2;
        }
    }
}
