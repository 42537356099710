/*
    Video Content Block
 */
.video-content-block {
    padding-top: rem(40);
    padding-bottom: rem(5);

    background-color: $color-light-shade-1;

    @media (--from-medium-screen-w) {
        padding-top: rem(60);
        padding-bottom: rem(60);
    }


    // Elements
    &-inner {
        @media (--from-medium-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }
    }

    &-credit {
        padding-top: rem(20);

        text-align: center;

        &::after {
            display: block;
            width: rem($hr-width);
            height: rem(2);
            max-width: calc(100% - rem(40));
            margin-top: rem(10);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-primary-tint-1;

            content: '';

            @media (--from-medium-screen-w) {
                max-width: 100%;
            }
        }
    }

    .section-header-title {
        margin-bottom: rem(40);

        @media (--to-normal-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }

        @media (--to-small-screen-w) {
            text-align: center;
        }
    }
}
