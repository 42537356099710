/*
    Way Finding Form
*/
.way-finding-form {
    display: none;

    width: 100%;

    @media (--from-normal-screen-w) {
        display: block;
    }


    // Types
    &.find-seat {
        display: block;

        .way-finding-form-field {
            align-items: center;
            height: rem(65);
        }

        .way-finding-form-label {
            min-width: rem(120);

            &:before {
                height: rem(65);
            }
        }

        .way-finding-form-input {
            font-family: $font-tertiary;

            @media (--to-normal-screen-w) {
                border-right: 1px solid $color-way-finding;
            }
        }
    }


    // Elements
    &-field {
        display: flex;
        justify-content: space-between;
        height: rem(55);


        // Types
        &:first-of-type {
            border: 1px solid $color-way-finding;
            border-right: none;
            border-left: none;
        }
    }

    &-label,
    &-input {
        font-family: $font-primary;
        font-size: rem(18);
    }

    &-label {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-width: rem(85);

        border-right: 1px solid $color-way-finding;


        // Types
        &:first-of-type&:before  {
            border-bottom: 1px solid $color-way-finding;
        }


        // Elements
        &:before {
            position: absolute;
            left: 0;

            width: rem(8);
            height: rem(55);

            background-color: $color-primary;

            content: '';
        }
    }

    &-input {
        z-index: $layer-way-finding-form-element;

        width: 100%;
        padding: rem(20);

        text-overflow: ellipsis;

        border: none;


        // States
        &:disabled {
            color: $color-secondary;

            background-color: $color-light;
        }

        &.is-invalid {
            color: rgb(209, 0, 26);
        }


        // Elements
        &::placeholder {
            color: rgb(216, 216, 216);
            font-family: $font-tertiary;
            font-size: rem(18);
        }
    }

    &-search-box {
        display: flex;
        width: 100%;
    }

    &-location-button {
        z-index: $layer-way-finding-form-element;

        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(230);
        padding: rem(10);

        font-family: $font-tertiary;
        font-size: rem(14);

        .icon {
            width: rem(32);
            height: rem(32);
        }
    }

    &-find-button {
        width: rem(100);
        margin: 0 rem(20) 0 rem(10);

        @media (--from-normal-screen-w) {
            margin: 0 rem(40) 0 rem(10);
        }
    }
}
