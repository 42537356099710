/*
    Call To Action
 */
.call-to-action {
    @media (--to-small-screen-w) {
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);
    }

    // Elements
    &-inner {
        @media (--to-small-screen-w) {
            background-color: transparent;
            box-shadow: 0 0 15px 5px $color-light-shade-2;
        }

        @media (--from-small-screen-w) {
            position: relative;
            display: flex;

            min-height: rem(600);
        }

        &[style*="background-image"] {
            @media (--to-small-screen-w) {
                background-image: none !important; // Override inline style
            }
        }

        // Elements
        &::before {
            // For the image overlay
            z-index: $layer-call-to-action-content;

            @media(--to-small-screen-w) {
                // Not need on small screens
                content: none;
            }
        }
    }

    &-main {
        position: relative;
        z-index: $layer-call-to-action-content;

        @media (--from-small-screen-w) {
            margin-top: auto;
            width: 100%;
            height: 100%;
        }
    }

    &-content {
        width: rem($constrain-width-small);
        max-width: 100%;
        padding-top: rem(20);
        padding-bottom: rem(20);

        @media (--from-small-screen-w) {
            padding-bottom: rem(30);
        }

        @media (--from-small-screen-w) and (--to-normal-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }


        // Elements
        .heading-2,
        h2 {
            width: rem(400);
            max-width: 100%;
            padding-bottom: rem(15);
            margin-bottom: rem(20);

            border-bottom: 2px solid $color-primary-tint-1;
        }
    }

    &-media {
        .image {
            display: block;

            @media (--from-small-screen-w) {
                width: 0;
                height: 0;
                opacity: 0;
            }
        }
    }
}
