/*
    Get in Touch
 */
.get-in-touch {
    position: relative;

    width: 100%;
    padding-bottom: rem(35);

    line-height: 1.7;

    background-color: transparent;

    @media (--from-normal-screen-w) {
        width: calc(50% - rem(100));
        padding-bottom: rem(55);
    }


    // Features
    body:not(.feature-stadiums-new-footer) & {
        padding-bottom: rem(55);

        color: $color-light;

        background-image: url($folder-image/field.jpg);
        background-size: cover;

        @media (--from-normal-screen-w) {
            background-image: none;
        }


        // Elements
        p a:not(.button),
        li a:not(.button) {
            color: $color-light-shade-1;

            &:hover {
                color: $color-light;

                border-bottom-color: $color-light;
            }
        }

        .labeled-content {
            width: rem(350);
    
            @media (--from-small-screen-w) {
                width: rem(400);
            }
    
    
            // Elements
            &-label {
                width: rem(75);
    
                color: $color-primary;
            }
        }
    }


    // Elements
    &::before {
        @media (--from-normal-screen-w) {
            background: transparent;
        }
    }
}
