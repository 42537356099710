/*
    Navigation
 */
$button-height: 65;

.navigation {
    @media (--to-medium-screen-w) {
        border-top: rem(2) solid $color-dark-tint-1;
        margin-bottom: rem(60);
    }

    @media (--from-medium-screen-w) {
        display: flex;
        align-items: center;
    }


    // Elements
    &-item {
        position: relative;

        @media (--to-medium-screen-w) {
            border-bottom: rem(2) solid $color-secondary-shade-1;
        }

        @media (--from-medium-screen-w) {
            display: flex;
            align-items: center;
            padding-right: rem(5);
            margin-right: rem(5);
            margin-left: rem(5);
        }

        @media (--from-normal-screen-w) {
            margin-right: rem(6);
            margin-left: rem(10);
        }


        // States
        &:hover,
        &.is-active {
            .sub-navigation {
                @media (--from-medium-screen-w) {
                    height: auto;
                    overflow: visible;

                    opacity: 1;

                    transform: translate(-50%, 0);
                    transition:
                        transform 0.2s ease-in-out,
                        opacity 0.2s ease-in-out;
                }
            }
        }

        &:hover .sub-navigation-toggle .icon {
            @media (--from-medium-screen-w) {
                transform: rotate(180deg);
            }
        }

        &.is-active {
            .sub-navigation {
                @media (--to-medium-screen-w) {
                    display: block;
                }
            }

            .sub-navigation-toggle .icon {
                transform: rotate(180deg);
            }
        }
    }

    &-link {
        display: flex;
        align-items: center;
        height: rem($button-height);

        color: $color-light;
        font-size: rem(18);
        line-height: 1;
        letter-spacing: 0.01em; // Increase light on dark
        text-decoration: none;

        @media (--to-medium-screen-w) {
            width: 100%;
            padding-left: rem(30);
        }

        @media (--from-medium-screen-w) {
            height: rem($header-height);
            padding-left: rem(5);

            color: $color-secondary;
            font-family: $font-primary;
            font-weight: $weight-semi-bold;
        }

        &:not(.is-non-clickable):hover {
            @media (--from-medium-screen-w) {
                color: $color-primary;
            }
        }

        &.is-non-clickable {
            user-select: none;
        }

        &.is-active {
            color: color($color-light alpha(60%));

            @media (--from-medium-screen-w) {
                color: $color-primary;
            }
        }
    }


    // Elements
    .sub-navigation-toggle {
        width: rem(32);
        height: rem(40);

        color: inherit;
        text-align: center;

        @media (--to-medium-screen-w) {
            position: absolute;
            top: 0;
            right: 0;

            width: rem(50);
            height: rem($button-height);
            padding-right: rem(10);

            body:not(.feature-stadiums-mobile-nav) & {
                display: none;
            }
        }

        .icon {
            display: inline-block;
            width: rem(35);
            height: rem(40);

            transition: transform 0.2s ease-in-out;

            @media (--from-medium-screen-w) {
                margin-right: rem(-10);
            }
        }
    }
}
