/*
    Event Hero Carousel
*/
$--hero-intro-width: 380;

.event-hero-carousel {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: rem(1300);
    margin-top: rem(32);
    overflow: hidden;


    @media (--from-normal-screen-w) {
        flex-direction: row;
    }


    // States
    &:hover .carousel .change-slide {
        opacity: 1;

        transition: opacity 0.2s ease-in-out 0.2s;

        &.is-disabled {
            opacity: 0.35;
        }
    }


    // Types
    &.desktop {
        @media (--to-small-screen-w) {
            display: none;
        }
    }

    &.mobile {
        @media (--from-small-screen-w) {
            display: none;
        }
    }

    &-intro {
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        padding: rem(25) rem(20);

        color: $color-light;

        background-color: $color-secondary;

        @media (--from-small-screen-w) {
            padding-right: rem(50);
            padding-left: rem(50);
        }

        @media (--from-normal-screen-w) {
            width: rem($--hero-intro-width);
            height: rem(500);
        }
    }

    &-heading {
        margin-bottom: rem(15);

        color: inherit;
        font-size: rem(20);
        font-weight: $weight-regular;
        text-align: left;


        @media (--from-small-screen-w) {
            font-size: rem(36);
        }

        &::after {
            display: block;
            width: 100%;
            height: rem(2);
            margin-top: rem(15);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-primary;

            content: '';
        }
    }

    &-detail {
        position: relative;

        display: block;
        padding-left: rem(35);
        margin-bottom: rem(15);


        // Types
        &.info {
            padding-left: 0;
        }


        // Elements
        .icon {
            position: absolute;
            top: rem(-2);
            left: 0;

            width: rem(24);
            height: rem(24);
        }
    }

    &-cta {
        margin-top: rem(20);

        a& {
            text-decoration: none;
        }
    }

    &-video {

        // Elements
        &-image {
            position: absolute;
            top: 0;
            left: calc(rem($--hero-intro-width));

            width: calc(100% - rem($--hero-intro-width));

            opacity: 1;

            transition: opacity ease-in-out 0.5s;


            // States
            &.is-hidden {
                opacity: 0;
            }


            // Pseudos
            &::before {
                content: none;
            }
        }

        .youtube-player {
            position: absolute;
            left: rem(190);
            z-index: $layer-youtube-iframe;

            order: 2;
            width: 100%;
            height: 100%;
            overflow: hidden;


            // Elements
            &-frame {
                position: absolute;
                top: 50%;

                width: 100%;
                height: 200%;

                transform: translateY(-50%);
            }
        }
    }

    .carousel {
        width: 100%;
        height: rem(180);

        @media (--from-small-screen-w) {
            height: rem(430)
        }

        @media (--from-normal-screen-w) {
            order: 2;
            width: calc(100% - rem($--hero-intro-width));
            height: rem(500)
        }


        // Elements
        &-inner {
            width: 100%;
            height: 100%;
        }

        &-image {
            &::before {
                content: none;
            }
        }

        .change-slide {
            @media (--to-medium-screen-w) {
                display: none;
            }

            @media (--from-medium-screen-w) {
                opacity: 0;

                transition: opacity 0.2s ease-in-out;
            }
        }
    }
}
