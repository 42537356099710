/*
    Footer Navigation
 */
.footer-navigation {
    @media (--to-small-screen-w) {
        text-align: center;
    }

    @media (--from-small-screen-w) {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + rem(40));
        margin-right: rem(-20);
        margin-left: rem(-20);
    }


    // Elements
    &-section {
        display: block;
        padding-right: rem(20);
        padding-left: rem(20);
        margin-bottom: rem(40);

        @media (--to-small-screen-w) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        @media (--from-small-screen-w) {
            flex-basis: calc(100% / 2);
            max-width: calc(100% / 2);
            margin-bottom: rem(20)
        }

        @media (--from-medium-screen-w) {
            flex-basis: calc(100% / 3);
            max-width: calc(100% / 3);
        }

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 4);
            max-width: calc(100% / 4);
        }


        // Relationship
        &:first-child {
            @media (--from-medium-screen-w) and (--to-normal-screen-w) {
                position: absolute;
                top: 0;
                right: 0;

                display: flex;
                justify-content: center;
                width: calc(100% - rem(145));
                max-width: 100%;
            }


            // Elements
            & > * {
                @media (--from-medium-screen-w) and (--to-normal-screen-w) {
                    margin-right: rem(40);
                    margin-left: rem(40);
                }
            }
        }
    }

    &-item {
        display: block;
    }
}
