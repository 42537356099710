/*
    Media
    - Image
    - Iframe
    - SVG
 */
// Image
picture,
img {
    display: block;
    height: auto;
    max-width: 100%;
}

figure {
    margin: 0;
}


// Iframe
iframe {
    width: 100%;
}


// SVG
svg {
    max-width: 100%;
}

use {
    width: 100%;
    height: 100%;
}
