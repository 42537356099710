/*
    Sub Navigation
 */
.sub-navigation {
    background-color: $color-secondary-shade-1;

    body.feature-stadiums-mobile-nav & {
        @media (--to-medium-screen-w) {
            display: none;
        }
    }

    @media (--from-medium-screen-w) {
        position: absolute;
        top: 100%;
        left: 50%;

        width: rem(280);
        height: 0;
        overflow: hidden;

        color: $color-dark;

        background-color: $color-light;
        box-shadow: $header-shadow;
        opacity: 0;

        transform: translate(-50%, rem(20));
        transition:
            height 0 ease-in-out 0.15s,
            overflow 0 ease-in-out 0.15s,
            transform 0.15s ease-in-out,
            opacity 0.15s ease-in-out;
    }


    // States
    &.is-active {
        color: $color-light;

        background-color: $color-primary;

        body.feature-stadiums-mobile-nav & {
            @media (--to-medium-screen-w) {
                display: none;
            }
        }
    }


    // Elements
    &::before {
        @media (--from-medium-screen-w) {
            position: absolute;
            top: rem(-10);
            left: 0;

            width: 100%;
            height: rem(10);

            background-color: $color-light;

            content: '';
        }
    }

    &-item {
        display: flex;
        align-items: center;
        height: rem(64);
        padding-right: rem(10);
        padding-left: rem(30);

        color: $color-light;
        text-decoration: none;

        transition:
            color ease-in-out 0.15s,
            background-color ease-in-out 0.15s;

        @media (--from-medium-screen-w) {
            justify-content: center;
            padding-left: rem(10);

            color: $color-dark-tint-2;
            font-size: rem(16);
            text-align: center;
        }


        // States
        &:hover {
            @media (--from-medium-screen-w) {
                color: $color-primary;
                text-decoration: underline;
            }
        }

        &.is-active {
            color: color($color-light alpha(60%));

            @media (--from-medium-screen-w) {
                color: $color-light;

                background-color: $color-primary;
            }
        }
    }
}
