/*
    Input
*/
.input {
    width: 100%;
    height: rem(38);
    max-width: 100%;

    color: $color-secondary;
    font-family: $font-tertiary;
    font-size: rem(18);

    background-color: transparent;

    border: 1px solid rgb(216, 216, 216);
    border-radius: rem(2);

    appearance: none;


    // States
    &:focus {
        border: 1px solid $color-primary;
        outline: 0;
    }

    &:hover {
        border: 1px solid $color-secondary;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;

        background: $color-light-shade-2;
    }

    &.is-invalid {
        border: 1px solid $color-warning;
    }


    // Elements
    &::placeholder {
        color: $color-silver;
    }
}
