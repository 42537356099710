/*
    Image Overlay
 */
.image-overlay {
    position: relative;

    background-color: $color-secondary-tint-1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    // Elements
    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;

        background: linear-gradient(to bottom, color($color-secondary alpha(0%)) 0%, color($color-secondary alpha(100%)) 100%);

        content: '';
    }
}
