/*
    Style Guide
 */
$--grid-spacing: 10;

.style-guide {

    // Elements
    &-section {
        margin-bottom: rem(40);

        @media (--from-normal-screen-w) {
            margin-bottom: rem(100);
        }

        &:first-of-type {
            padding-top: rem(40);

            @media (--from-normal-screen-w) {
                padding-top: rem(50);
            }
        }
    }

    &-sub-heading {
        margin-bottom: rem(10);
    }

    &-swatches {
        margin-right: calc(rem(-$--grid-spacing) / 2);
        margin-bottom: rem($--grid-spacing);
        margin-left: calc(rem(-$--grid-spacing) / 2);

        @media (--from-tiny-screen-w) {
            display: flex;
            flex-wrap: wrap; // IE requires max-width on children
        }


        // Elements
        .swatch {
            flex-basis: 100%;
            padding-right: calc(rem($--grid-spacing) / 2);
            padding-left: calc(rem($--grid-spacing) / 2);
            margin-bottom: rem($--grid-spacing);

            @media (--from-small-screen-w) {
                flex-basis: calc(100% / 3);
                max-width: calc(100% / 3);
            }

            @media (--from-medium-screen-w) {
                flex-basis: calc(100% / 4);
                max-width: calc(100% / 4);
            }
        }
    }

    &-button-group {
        margin-bottom: rem(20);

        > * {
            margin-right: rem(10);

            vertical-align: top;
        }
    }

    &-line {
        @media (--from-medium-screen-w) {
            margin-bottom: rem(20);

            border-bottom: rem(10) solid $color-dark;
        }


        // Sizes
        &.x-large::after {
            content: ': ' '$constrain-width-x-large' 'px';
        }

        &.large::after {
            content: ': ' '$constrain-width-large' 'px';
        }

        &.medium::after {
            content: ': ' '$constrain-width-medium' 'px';
        }

        &.small::after {
            content: ': ' '$constrain-width-small' 'px';
        }


        // Elements
        &::after {
            content: ': ' '$constrain-width' 'px';
        }
    }

    &-icons > * {
        display: inline-block;
        margin-right: rem(10);

        color: $color-secondary;
    }

    &-logos > * {
        display: inline-block;
        margin-right: rem(20);
    }
}
