/*
    RTE Content
 */
.rte-content {

    // Types
    /* The "light" variation inverts the colors on tablet and desktop only. */
    &.light {
        @media (--from-small-screen-w) {
            color: $color-light;


            // Link
            p a:not(.button),
            li a:not(.button) {
                color: $color-light-shade-1;

                &:hover {
                    color: $color-light;

                    border-bottom-color: $color-light;
                }
            }
        }
    }


    // Elements
    img {
        margin-bottom: rem(20);
    }

    a.button {
        margin-bottom: rem(7);
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
