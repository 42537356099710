/*
    Section
 */
.section {
    padding-top: rem(40);
    margin-bottom: rem(40);


    // Types
    &.is-last-on-page {
        margin-bottom: rem($section-spacing);

        @media (--from-medium-screen-w) {
            margin-bottom: rem($section-spacing-x-large);
        }
    }
}
