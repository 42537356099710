/*
    Logo
 */
.logo {
    display: block;

    color: $color-secondary;


    // Themes
    &.light {
        color: $color-light;
    }
}
