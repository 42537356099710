/*
    Link
*/
.link {
    display: flex;
    align-items: center;
    min-height: rem(30);
    margin-bottom: rem(10);

    color: $color-secondary;
    text-decoration: none;

    transition: color 0.15s ease-in-out;


    // States
    &:hover {
        color: $color-primary;

        border-bottom-color: $color-primary;
    }


    // Types
    &.large {
        font-size: rem(18);
        letter-spacing: 0.02em;
    }


    // Elements
    .icon {
        width: rem(30);
        height: rem(30);
        margin-right: rem(6);
        margin-left: rem(-5);
    }
}
