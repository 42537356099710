/*
    Carousel
*/
$--carousel-max-height: 600;

.carousel {
    // Elements
    &-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media(--from-medium-screen-w) {
            max-height: rem($--carousel-max-height);
        }
    }

    &-media {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-image-carousel-slide;

        width: 100%;
        height: 100%;

        opacity: 0;

        transition: transform 1s ease, opacity 1s ease;


        // States
        &.is-active {
            z-index: $layer-image-carousel-active-slide;

            opacity: 1;

            transform: scale(1);
        }

        &.is-next {
            opacity: 0;

            transform: scale(1.2);
        }
    }

    &-inner {
        position: relative;
    }

    .preserve-aspect-ratio {
        overflow: hidden;
    }
}
