/*
    Form
    - Button
    - Input
    - Textarea
    - Select
 */
// Button
button,
button[type='submit'] {
    padding: 0;

    line-height: 1;
    white-space: nowrap;

    cursor: pointer;
    background: none;
    border: none;
    border-radius: 0;

    appearance: none;
}


// Input
input[type='email'],
input[type='password'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'] {
}


// Textarea
textarea {
    max-height: rem(400);

    line-height: inherit;
}


// Select
select {
    height: rem(40);
}
