/*
    Faux required input
 */
.faux-required-input {
    display: block;
    width: 0;

    &-field {
        display: block;
        height: 0;

        overflow: hidden;
    }
}
