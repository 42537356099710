/*
    File Download
 */
.file-download {
    position: relative;

    display: flex;
    flex-direction: column;
    width: rem(700);
    max-width: 100%;
    padding: rem(20);
    margin-bottom: rem(20);

    background-color: $color-light-shade-1;
    border-radius: rem(6);

    @media (--to-small-screen-w) {
        justify-content: center;
    }

    @media (--from-small-screen-w) {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding-right: rem(30);
        padding-left: rem(30);
    }


    // Elements
    &-details {
        position: relative;

        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        padding-left: rem(65);
    }

    &-title {
        margin-bottom: rem(3);

        font-size: rem(18);
    }

    &-text {
        margin-bottom: 0;

        font-size: rem(15);
    }

    .button {
        flex-shrink: 0;
        margin-left: rem(15);

        @media (--to-small-screen-w) {
            margin-top: rem(15);
            margin-right: auto;
            margin-left: auto;
        }
    }

    .icon {
        position: absolute;
        left: 0;

        width: rem(50);
        height: rem(50);
        margin-right: rem(10);

        color: $color-primary;
    }
}
