/*
    Button
 */
.button,
button.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(40);
    padding-right: rem(30);
    padding-left: rem(30);

    color: $color-light;
    font-family: $font-tertiary;
    font-size: rem(16);
    line-height: rem(20);
    letter-spacing: 0.05em;
    text-decoration: none;

    background-color: $color-secondary;
    border: 1px solid currentColor;
    border-radius: rem(50);

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, opacity 0.2s;

    @media (--from-medium-screen-w) {
        height: rem(48);
        padding-right: rem(50);
        padding-left: rem(50);
    }


    // States
    &:hover {
        background-color: $color-secondary-tint-1;
    }

    &:disabled,
    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;

        opacity: 0.7;
    }

    &:active,
    &.is-active {
        background-color: $color-secondary-shade-1;
    }


    // Types
    &.block {
        display: block;
        width: 100%;
    }


    // Sizes
    &.small {
        height: rem(30);
        padding-right: rem(10);
        padding-left: rem(10);

        font-size: rem(14);

        .button-icon {
            width: rem(20);
            height: rem(20);
        }
    }

    &.large {
        height: rem(50);
        padding-right: rem(30);
        padding-left: rem(30);

        font-size: rem(18);

        .button-icon {
            width: rem(40);
            height: rem(40);
        }
    }


    // Themes
    &.primary {
        color: $color-light;

        background-color: $color-primary;
        border-color: $color-primary;


        // States
        &:hover {
            background-color: $color-primary-tint-1;
            border-color: $color-primary-tint-1;
        }

        &:active,
        &.is-active {
            background-color: $color-primary-shade-1;
            border-color: $color-primary-shade-1;
        }
    }
}
