/*
    Form - Inline
 */
.form-inline {
    border: none;
    padding: 0;

    .field-group {
        display: flex;

        input + button {
            border-radius: 0;
            border-top-right-radius: rem(2);
            border-bottom-right-radius: rem(2);
            padding: 0 rem(10);
            height: rem(38);
            font-size: rem(14);
        }
    }

    input {
        flex-grow: 1;
        padding: rem(13);

        background-color: $color-white;
        border-radius: 0;
        border-top-left-radius: rem(2);
        border-bottom-left-radius: rem(2);

        &::placeholder {
            color: $color-secondary;
        }
    }
}
