.search {
    &-icon {
        color: $color-wasabi;
        margin-left: rem(8);

        @media (--from-navigation-breakpoint) {
            margin-right: rem(8);
        }
        &:hover {
            color: color($color-wasabi alpha(60%));
        }
    }
}